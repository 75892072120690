import React from "react";

import { Header } from "../components/parts/Header";
import { News } from "../components/News";
import { Footer } from "../components/Footer";

import { Layout } from "../components/parts/Layout";

const PrivacyPage = () => {
  return (
    <Layout>
      <Header
        title={"お知らせ"}
        description={"お知らせ"}
      />
      <News />
      <Footer />
    </Layout>
  )
}

export default PrivacyPage
